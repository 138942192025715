import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import Container from "./ui/container";
import Section from "./ui/section";

interface Item {
  title: string;
  url: string;
  image: string;
}

interface Props {
  items: Item[];
}

interface State {
  hoverIndex: number;
}

const BoxContainer = styled.div<{ image?: string }>`
  background-image: url(${(props) => (props.image ? props.image : "")});
  background-size: cover;
  background-position: center;
  height: 240px;
  @media only screen and (min-width: 640px) {
    height: 250px;
  }
  @media only screen and (min-width: 768px) {
    height: 280px;
  }
  @media only screen and (min-width: 1024px) {
    height: 310px;
  }
  @media only screen and (min-width: 1280px) {
    height: 340px;
  }
  @media only screen and (min-width: 1550px) {
    height: 370px;
  }
  @media only screen and (min-width: 1700px) {
    height: 400px;
  }
`;

const Box = styled.div<{ hover: boolean }>`
  transition: ${(props) => (props.hover ? "max-height 1s ease-in" : "")};
  max-height: ${(props) => (props.hover ? "600px" : "100px")};
  height: ${(props) => (props.hover ? "100%" : "auto")};
`;

class Featured extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hoverIndex: -1,
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }
  handleMouseEnter(event: React.MouseEvent, index: number) {
    this.setState({ hoverIndex: index });
  }
  handleMouseLeave(event: React.MouseEvent) {
    this.setState({ hoverIndex: -1 });
  }
  public render() {
    const { items } = this.props;
    return (
      <div id="main">
        <Section>
          <Container padding>
            <div className="flex justify-around flex-wrap">
              {items.map((item, i) => (
                <Link
                  to={item.url ? item.url : ""}
                  key={i}
                  onMouseEnter={(e) => this.handleMouseEnter(e, i)}
                  onMouseLeave={(e) => this.handleMouseLeave(e)}
                  className={
                    "w-full lg:w-5/12 bg-white mb-10" + (i % 2 == 0 ? " " : "")
                  }
                >
                  {item.image && (
                    <BoxContainer
                      className="flex flex-col"
                      image={`${item.image}`}
                    >
                      <Box
                        className={
                          "mt-auto bg-blue-800 bg-opacity-75 py-4 px-4 flex flex-col justify-center items-center"
                        }
                        hover={this.state.hoverIndex === i}
                      >
                        <p className="break-words text-2xl md:text-4xl text-gray-200 font-bold">
                          {item.title}
                        </p>
                      </Box>
                    </BoxContainer>
                  )}
                </Link>
              ))}
            </div>
          </Container>
        </Section>
      </div>
    );
  }
}

export default Featured;
