import * as React from "react";
import { graphql } from "gatsby";

import BasicLayout from "../layouts/BasicLayout";
import Hero from "../components/hero";
import Featured from "../components/featured";

const featured = [
  {
    title: "Products",
    url: "/products",
    image: `images/products.jpg`,
  },
  {
    title: "Educational Services",
    url: "/services",
    image: "images/services.jpg",
  },
];

export default function IndexPage(props) {
  const { data } = props;
  const { site } = data;

  return (
    <div>
      {/* <SEO /> */}
      <BasicLayout pageTitle="Home">
        <Hero
          logo={site.logo}
          heading={site.title}
          subheading={site.statement}
          image={site.hero}
        />
        <Featured items={featured} />
      </BasicLayout>
    </div>
  );
}

export const IndexQuery = graphql`
  query HomePageQuery {
    site: dataYaml(type: { eq: "site_info" }) {
      title
      hero
      statement
      metadata
      logo
      Subtitle
    }
  }
`;
