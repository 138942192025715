import * as React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";

const HeroImage = styled.div<{ image: string }>`
  background-image: url(${(props) => props.image});
`;

interface Props {
  logo: string;
  heading: string;
  subheading: string;
  image: string;
}

interface State {}

class Hero extends React.Component<Props, State> {
  public render() {
    return (
      <HeroImage
        image={this.props.image}
        className="h-screen bg-cover bg-blue-900 bg-center bg-no-repeat"
      >
        <div className="bg-blue-800 bg-opacity-75 w-full h-full">
          <div className="text-center text-white h-full px-5 xl:px-0 py-32 sm:py-2 md:py-10 lg:py-32 flex flex-col items-center justify-center">
            <img
              className="w-32 md:w-56 mx-auto mb-4"
              src={this.props.logo}
              alt=""
            />
            <h1 className="text-3xl sm:text-4xl md:text-3xl lg:text-6xl font-bold font-serif text-center">
              {this.props.heading}
            </h1>
            <p className="text-lg md:text-lg lg:text-2xl text-center">
              {this.props.subheading}
            </p>
            <button
              className="cursor-pointer mt-4"
              onClick={() => scrollTo("#main")}
            >
              <FontAwesomeIcon
                className="text-5xl hover:text-gray-300"
                style={{ maxWidth: "48px" }}
                icon={faArrowCircleDown}
              />
            </button>
          </div>
        </div>
      </HeroImage>
    );
  }
}

export default Hero;
